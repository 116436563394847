:root {
  --toastify-color-light: #ffffff;
  --toastify-color-dark: #202245;
  --toastify-color-info: #a0a0a0;
  --toastify-color-success: #1b7742;
  --toastify-color-warning: #ff7e00;
  --toastify-color-error: #a31f28;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: Poppins-Medium;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
}
